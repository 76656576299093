import React from 'react';
import {
  type MRT_ColumnDef as MrtColumnDef,
  type MRT_Row as MrtRow,
} from 'material-react-table';
import { DateCell } from '../../app/Timezone';
import { useGetUsers } from '../../../api/query/user/users';
import MrtDataGrid from '../common/MrtDataGrid';
import { styleObj } from '../../../styles/rowStyle';
import CustomDateFilter from '../common/date/CustomDateFilter';
import { useTableSearchParams } from '../hooks/useTableSearchParams';

type User = {
  quickbloxUserId: string;
  userName: string;
  fullName: string;
  phone: string;
  region: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

function Users() {
  const {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    showDeleted,
    setShowDeleted,
    dateFilter,
    setDateFilter,
    queryParams,
    clearFilters,
  } = useTableSearchParams();

  const columns: MrtColumnDef<User>[] = [
    {
      accessorKey: 'quickbloxUserId',
      header: 'User ID',
      size: 120,
    },
    {
      accessorKey: 'userName',
      header: 'Username',
      size: 150,
    },
    {
      accessorKey: 'fullName',
      header: 'Fullname',
      size: 150,
    },
    {
      accessorKey: 'phone',
      header: 'Phone',
      size: 130,
    },
    {
      accessorKey: 'region',
      header: 'Region',
      size: 120,
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'createdAt', filter: dateFilter, setFilter: setDateFilter }),
    },
    {
      accessorKey: 'updatedAt',
      header: 'Updated At',
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'updatedAt', filter: dateFilter, setFilter: setDateFilter }),
    },
    {
      accessorKey: 'deletedAt',
      header: 'Deleted At',
      enableSorting: false,
      size: 190,
      Cell: DateCell,
      Filter: () => CustomDateFilter({ field: 'deletedAt', filter: dateFilter, setFilter: setDateFilter }),
    },
  ];

  const {
    data, isError, isLoading, refetch,
  } = useGetUsers(queryParams);

  const customStyle = (row: MrtRow<User>) => (row.original.deletedAt ? styleObj.error : {});

  return (
    <MrtDataGrid
      columns={columns}
      data={data?.rows || []}
      totalRows={data?.totalRows ?? 0}
      isLoading={isLoading}
      isError={isError}
      onRefetch={refetch}
      onClearFilters={clearFilters}
      onColumnFiltersChange={setColumnFilters}
      pagination={pagination}
      setPagination={setPagination}
      setSorting={setSorting}
      sorting={sorting}
      customRowStyle={customStyle}
      showDeletedCheckbox
      showDeleted={showDeleted}
      setShowDeleted={setShowDeleted}
    />
  );
}

export default Users;
