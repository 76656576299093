import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MRT_PaginationState as MrtPaginationState, MRT_SortingState as MrtSortingState, MRT_ColumnFiltersState as MrtColumnFiltersState } from 'material-react-table';
import { FilterParams, QueryParams } from '../../../api/query/params';

export function useTableSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getInitialValues = () => ({
    pageIndex: parseInt(searchParams.get('page') || '0', 10),
    pageSize: parseInt(searchParams.get('pageSize') || '25', 10),
    sortField: searchParams.get('sortField') || '',
    sortDir: searchParams.get('sortDir') === 'desc',
    showDeleted: searchParams.get('showDeleted') !== 'false',
    searchField: searchParams.get('searchField') || '',
    searchValue: searchParams.get('searchValue') || '',
    filterField: searchParams.get('filterField') || '',
    filterOp: searchParams.get('filterOp') || '',
    filterValue: searchParams.get('filterValue') || '',
  });

  const [pagination, setPagination] = useState<MrtPaginationState>({
    pageIndex: getInitialValues().pageIndex,
    pageSize: getInitialValues().pageSize,
  });
  const [sorting, setSorting] = useState<MrtSortingState>(
    getInitialValues().sortField ? [{ id: getInitialValues().sortField, desc: getInitialValues().sortDir }] : [],
  );
  const [columnFilters, setColumnFilters] = useState<MrtColumnFiltersState>(
    getInitialValues().searchField ? [{ id: getInitialValues().searchField, value: getInitialValues().searchValue }] : [],
  );
  const [showDeleted, setShowDeleted] = useState(getInitialValues().showDeleted);
  const [dateFilter, setDateFilter] = useState<FilterParams>({
    filterField: getInitialValues().filterField,
    filterOp: getInitialValues().filterOp,
    filterValue: getInitialValues().filterValue,
  });

  const [queryParams, setQueryParams] = useState<QueryParams>({
    limit: pagination.pageSize,
    offset: pagination.pageIndex * pagination.pageSize,
    showDeleted,
  });

  const syncSearchParams = useCallback(
    () => {
      const newParams = new URLSearchParams();

      if (pagination.pageIndex !== 0 || pagination.pageSize !== 25) {
        newParams.set('page', pagination.pageIndex.toString());
        newParams.set('pageSize', pagination.pageSize.toString());
      }

      if (sorting[0]?.id) {
        newParams.set('sortField', sorting[0]?.id);
        newParams.set('sortDir', sorting[0]?.desc ? 'desc' : 'asc');
      }

      if (dateFilter.filterField) {
        newParams.set('filterField', dateFilter.filterField);
        newParams.set('filterOp', dateFilter.filterOp || '');
        newParams.set('filterValue', dateFilter.filterValue || '');
      }

      if (columnFilters[0]?.id) {
        newParams.set('searchField', columnFilters[0].id);
        newParams.set('searchOp', 'contains');
        newParams.set('searchValue', columnFilters[0]?.value as string);
      }

      newParams.set('showDeleted', showDeleted.toString());

      if (Array.from(newParams.entries()).length > 0) {
        setSearchParams(newParams);
      } else {
        setSearchParams({});
      }
    },
    [pagination, sorting, columnFilters, showDeleted, dateFilter, setSearchParams],
  );

  useEffect(() => {
    syncSearchParams();
    setQueryParams({
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      sortField: sorting[0]?.id || '',
      dir: sorting[0]?.desc ? 'desc' : 'asc',
      showDeleted,
      filterField: dateFilter.filterField,
      filterOp: dateFilter.filterOp,
      filterValue: dateFilter.filterValue,
      searchField: columnFilters[0]?.id,
      searchOp: columnFilters[0] ? 'contains' : '',
      searchValues: columnFilters[0]?.value as string || '',
    });
  }, [pagination, sorting, columnFilters, showDeleted, dateFilter, syncSearchParams]);

  const clearFilters = () => {
    setSearchParams({});
    setColumnFilters([]);
    setSorting([]);
    setShowDeleted(false);
    setDateFilter({ filterField: '', filterOp: '', filterValue: '' });
    setPagination({ pageIndex: 0, pageSize: 25 });
  };

  return {
    pagination,
    setPagination,
    sorting,
    setSorting,
    setColumnFilters,
    showDeleted,
    setShowDeleted,
    dateFilter,
    setDateFilter,
    queryParams,
    clearFilters,
  };
}
