import { Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import AdminUsers from './components/adminUser/AdminUsers'
import AdminUserRoles from './components/adminUserRole/AdminUserRoles'
import AnalyticsCampaign from './components/analytics/Campaign'
import KpiDB from './components/analytics/KpiDB'
import KpiDBCustom from './components/analytics/KpiDBCustom'
import Layout from './components/app/Layout'
import GiftCodeDetails from './components/campaign/GiftCodeDetails'
import GiftCodes from './components/campaign/GiftCodes'
import Pages from './components/campaign/Pages'
import Summary from './components/campaign/Summary'
import WeeklyLikeMomentDetailsRouter from './components/campaign/WeeklyLikeMomentDetailsRouter'
import WeeklyLikeMoments from './components/campaign/WeeklyLikeMoments'
import Login from './components/common/Login'
import Dashboard from './components/dashboard/Dashboard'
import DialogDetailsRouter from './components/dialog/DialogDetailsRouter'
import Dialogs from './components/dialog/Dialogs'
import Chat from './components/chat/Chats'
import Explore from './components/dialog/Explore'
import JobDetails from './components/job/JobDetails'
import Jobs from './components/job/Jobs'
import Maintenance from './components/maintenance/Maintenance'
import UploadImages from './components/uploadImages/UploadImages'
import MessageReportDetails from './components/message-reports/MessageReportDetails'
import MessageReports from './components/message-reports/MessageReports'
import MomentReportDetails from './components/moment-reports/MomentReportDetails'
import MomentReports from './components/moment-reports/MomentReports'
import MomentDetailsRouter from './components/moment/MomentDetailsRouter'
import Moments from './components/moment/Moments'
import MomentsHalloween from './components/moment/MomentsHalloween'
import MomentsChristmas from './components/moment/MomentsChristmas'
import MomentsNewYear from './components/moment/MomentsNewYear'
import MomentsWinter from './components/moment/MomentsWinter'
import MomentsFurryFriends from './components/moment/MomentsFurryFriends'
import Revisions from './components/revisions/Revisions'
import UserReportDetails from './components/user-reports/UserReportDetails'
import UserReports from './components/user-reports/UserReports'
import UserDetailsRouter from './components/user/UserDetailsRouter'
import { getStorage } from './services/storage'
import Featured from './components/dialog/Featured'
import ChatDetails from './components/chat/ChatDetails'
import TextFilter from './components/contentModeration/TextFilter'
import { RootProvider } from './provider/RootProvider'
import Users from './components/v2/user/Users'

function App() {
  const loggedIn = getStorage('loggedIn') === 'true'
  const userName = getStorage('userName', '')
  const userRole = getStorage('userRole', '')

  return (
    <RootProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Routes>
          {
            loggedIn
              ? (
                <Route element={<Layout userName={userName} userRole={userRole} />}>
                  <Route path="/users">
                    <Route path="" element={<Users />} />
                    <Route path=":quickbloxUserId" element={<UserDetailsRouter />} />
                  </Route>
                  <Route path="/dialogs">
                    <Route path="" element={<Dialogs />} />
                    <Route path=":quickbloxDialogId" element={<DialogDetailsRouter />} />
                    <Route path="explore" element={<Explore />} />
                    <Route path="featured" element={<Featured />} />
                  </Route>
                  <Route path="/moments">
                    <Route path="" element={<Moments />} />
                    <Route path="halloween" element={<MomentsHalloween />} />
                    <Route path="newYear" element={<MomentsNewYear />} />
                    <Route path="christmas" element={<MomentsChristmas />} />
                    <Route path="winter" element={<MomentsWinter />} />
                    <Route path="furryFriends" element={<MomentsFurryFriends />} />
                    <Route path=":identifier" element={<MomentDetailsRouter />} />
                  </Route>
                  <Route path="/reports">
                    <Route path="users">
                      <Route path="" element={<UserReports />} />
                      <Route path=":identifier" element={<UserReportDetails />} />
                    </Route>
                    <Route path="moments">
                      <Route path="" element={<MomentReports />} />
                      <Route path=":identifier" element={<MomentReportDetails />} />
                    </Route>
                    <Route path="messages">
                      <Route path="" element={<MessageReports />} />
                      <Route path=":identifier" element={<MessageReportDetails />} />
                    </Route>
                    <Route path="chatMessages">
                      <Route path="" element={<Chat />} />
                      <Route path=":identifier" element={<ChatDetails />} />
                    </Route>
                  </Route>
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/revisions" element={<Revisions />} />
                  <Route path="/analytics">
                    <Route path="kpi/db" element={<KpiDB />} />
                    <Route path="kpi/db/custom" element={<KpiDBCustom />} />
                    <Route path="campaign" element={<AnalyticsCampaign />} />
                  </Route>
                  <Route path="/admin">
                    <Route path="users" element={<AdminUsers />} />
                    <Route path="userRoles" element={<AdminUserRoles />} />
                  </Route>
                  <Route path="/contentModeration">
                    <Route path="/contentModeration/textFilters" element={<TextFilter />} />
                  </Route>
                  <Route path="/uploadImages" element={<UploadImages />} />
                  <Route path="/campaign">
                    <Route path="giftCodes" element={<GiftCodes />} />
                    <Route path="giftCodes/:id" element={<GiftCodeDetails />} />
                    <Route path="pages" element={<Pages />} />
                    <Route path="summary" element={<Summary />} />
                    <Route path="weeklyLikeMoments" element={<WeeklyLikeMoments />} />
                    <Route path="weeklyLikeMoments/:weeklyLikeMomentId" element={<WeeklyLikeMomentDetailsRouter />} />
                  </Route>
                  <Route path="/jobs">
                    <Route path="" element={<Jobs />} />
                    <Route path=":identifier" element={<JobDetails />} />
                  </Route>
                  <Route path="/dashboard">
                    <Route path="" element={<Dashboard />} />
                  </Route>
                  <Route path="/*" element={<Redirect path="/dashboard" />} />
                </Route>
              )
              : (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/*" element={<Redirect path="/login" />} />
                </>
              )
          }
        </Routes>
      </Box>
    </RootProvider>
  )
}

function Redirect(props: { path: string }) {
  const { path } = props
  const location = useLocation()

  return (
    <Navigate to={path} state={{ from: location }} replace />
  )
}

export default App
